import useScript from './useScript';

export default ({ vimeoId, padding }) => {
    useScript('https://player.vimeo.com/api/player.js')
    return (
      <div
        className="video shadow-xl"
        style={{
            border: '3px solid #252525',
            marginBottom: 10,
            marginTop: 20,
          position: "relative",
          paddingBottom: `${padding}%`,
          paddingLeft:0,
          paddingRight:0,
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={`https://player.vimeo.com/video/${vimeoId}?color=ff9933&title=0&byline=0&portrait=0`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      
    )
  }
