import { Container, Row, Col, Button, Media, Image, Collapse } from 'react-bootstrap';
import { useState, useLayoutEffect, useRef } from 'react'
import FadeInSection from './FadeInSection'
import Vimeo from './Vimeo'
import { Tooltip } from 'reactstrap';

function Folio() {
    const [openPF, setOpenPF] = useState(false)
    const [openVS, setOpenVS] = useState(false)
    const [openVA, setOpenVA] = useState(false)
    const [openBC, setOpenBC] = useState(false)
    const [openSN, setOpenSN] = useState(false)
    const [openEC, setOpenEC] = useState(false)
    const [portraitWidth, setPortraitWidth] = useState()
    const [narrow, setNarrow] = useState()
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const toggle = () => setTooltipOpen(!tooltipOpen);

    // Resize on window resize
    const elementRef = useRef(null);
    useLayoutEffect(() => {
        function updateWidth() {
            window.innerWidth < 800 ? setNarrow(true) : setNarrow(false)
            setPortraitWidth(document.getElementById('hero').offsetWidth / 2)
            // setPortraitWidth(window.innerWidth / 2)
            // Replaced /#urls with simple /vs etc
            // let { hash } = window.location
            // if (hash.length > 1) {
            //     hash = hash.slice(1)
            //     window.onload = (event) => {
            //         scrollTo(hash)
            //     };
            // }
            let hash = window.location.pathname
            console.log(hash)
            if (hash.length > 1) {
                hash = hash.slice(1)
                window.onload = (event) => {
                    scrollTo(hash)
                };
            }
        }
        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    function scrollTo(id) {
        const yOffset = -50; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
    
    return (
        <Container fluid className="main center justify-content-center" ref={elementRef} >
           
            <div id="hp"> 
                {/* <Row className="pb-5 bg-header" > */}
                <Row className="hero" >
                    <Col id="hero" xs={12} lg={12} className="content" style={{paddingTop:0}}>
                        <div className="hero-portrait">
                            <Image id="portrait" src=".//img/portrait_bw3.jpg" className="portrait" fluid style={{paddingBottom:0}}/>
                        </div>

                        <div className="hero-text px-3" style={{position:"absolute", paddingRight:20, top: narrow ? 20 : 150, left: narrow ? 20 : portraitWidth-40, maxWidth:500}}>
                            <h1 style={{fontSize:140, padding:0, margin:0, textAlign:"left"}}>Hello</h1> 
                            <h4 className="" style={{textAlign:"left"}}>I’m Mindaugas, a Software Engineer & Architect based in Zürich, Switzerland.</h4>
                            {/* <h4 className="" style={{textAlign:"left"}}>I’m Mindaugas Petrosius, a <a onClick={() => scrollTo('pf')} className="links">Software Engineer</a> , <a href="https://architecture.petrosius.com" target="_blank" className="links">Architect</a> , <a href="https://imaginevisuals.com" target="_blank" className="links">Creative Director</a> and <a href="https://guspetro.com" target="_blank" className="links">Artist</a> based in Zürich, Switzerland.</h4> */}
                            <a className="float-left mt-1 email cryptedmail" href="mailto:m@mindaugas.xyz">m@mindaugas.xyz</a>
                            
                            {/* <div className={ narrow ? "hidden" : "hero-arrow"}>
                                <Image id="arrow" src=".//img/down_arrow.png" className="arrow" onClick={() => scrollTo('pf')}/>
                            </div> */}
                            <Image id="arrow" src=".//img/down_arrow.png" className={ narrow ? "hidden" : "arrow"} onClick={() => scrollTo('pf')}/>

                            {/* <Button
                                id="TooltipExample"
                                className="button float-left mt-1 email"
                                variant=""
                                style={{paddingLeft:0, fontColor:"black !important", fontSize:24}}
                                >
                                <a className="cryptedmail" href="mailto:m@mindaugas.xyz">m@mindaugas.xyz</a>
                                <a href="#" className="cryptedmail"
                                    data-name="m"
                                    data-domain="mindaugas"
                                    data-tld="xyz"
                                    onClick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld;"
                                    >
                                    </a>
                            </Button>
                            <Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle} style={{fontSize:24, paddingRight:20, paddingLeft:20}}>
                                Obfuscated!
                            </Tooltip> */}
                        </div>
                        
                        {/* <Image id="portrait" src=".//img/portrait_bw3.jpg" fluid className="float-left" style={{paddingTop:80, width: narrow ? '100%' : '60%'}}/> */}

                    </Col>
                </Row>
            </div>
            
            
            <div id="pf" >
            <FadeInSection>
                <Row name="pathfinder" className="mt-5 pt-3 m-3 justify-content-center">
                    <Media >
                        <Image src=".//img/pf/pf18.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Pathfinder</h2>
                        <h4>Vizualize pathfinding algorithms. Build a maze.</h4>
                        <h4>Experience six maze generation and five search algorithms.</h4>
                        <h5>React · JavaScript · Node.js · CSS · HTML · Material Design · UI · UX</h5>
                        <Button
                            href='https://mindaugas.xyz/pathfinder'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenPF(!openPF)}
                            aria-controls="pf-collapse-text"
                            aria-expanded={openPF}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openPF}>
                            <div id="pf-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518928077' padding='62.5' />

                                    <h3>Pathfinding Visualizer</h3>
                                    Built with Javascript and React Pathfinder visualizes various pathfinding algorithms in action, and more! At its core, a pathfinding algorithm seeks to find the shortest path between two points.
                                    After generating or building their own maze users can execute any of the given search algorithms to find the shortest path. The application is interactive, allowing users to reposition start and finish nodes, and this way giving it a playfulness that reassembles a small computer game.

                                    <h3>UI</h3>
                                    User Interface is kept clean and minimal, allowing users to explore all the powerful functionality of the application themselves.
                                    In the navigation bar there are two main buttons. One to generate a maze and the other to perform a path search. Icons indicate a few additional but powerful settings including ‘Refresh’ to clean the search paths, ‘Trash-box’ to erase everything including walls, and a few toggle button-groups to resize the grid and change the speed of animation. The main screen space takes a grid, where already are two pointers - the start and finish nodes - ready to be positioned. 
                                    <Image src=".//img/pf/pf1.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />
                                    
                                    <h3>Maze Algorithms</h3>
                                    Users are able to draw their own maze over the grid or to generate it using one of the six maze generation algorithms including Random Traversal, Depth-First, Prim’s, Recursive, Binary Tree or Wilson’s Maze.

                                    <h3>Random Traversal</h3>
                                    <p>Random Traversal algorithm, sometimes misleadingly called randomized Prim’s algorithm, is more accurately described as a random traversal. Starting in the upper-left corner, the algorithm keeps an array of the possible directions the maze could be extended (shown in vivid colors). At each step, the maze is extended in one of these random directions, as long as doing so does not reconnect with another part of the maze.</p>
                                    <p>While this algorithm also generates a spanning tree, its behavior is radically different from running Prim’s algorithm on a randomly-weighted graph! Random traversal generates mazes with a very predictable global structure which can be seen in the above the animation.</p>
                                    Random traversal behaves similarly to randomized breadth-first traversal, since typically the maze can only be extended without self-intersecting on a roughly-circular perimeter. 
                                    <Image src=".//img/pf/pf4.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Depth-First Maze</h3>
                                    Depth-First Maze generation algorithm uses randomized depth-first traversal. Starting in the upper-left corner, the algorithm keeps an array of the possible directions the maze could be extended (shown in vivid colors). At each step, the maze is extended in a random direction from the previous cell, as long as doing so does not reconnect with another part of the maze.                                    
                                    <Image src=".//img/pf/pf8.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Prim’s Maze</h3>
                                    <p>Prim’s algorithm generates a minimum spanning tree from a graph with weighted edges. Starting in the upper-left corner, the algorithm keeps a heap of the possible directions the maze could be extended (shown in vivid colors). At each step, the maze is extended in the direction with the lowest weight, as long as doing so does not reconnect with another part of the maze. Here the edges are initialized with random weights.</p>                                    
                                    Unlike Wilson’s algorithm, this does not result in a uniform spanning tree. Sometimes, random traversal is misleadingly referred to as randomized Prim’s algorithm; however, the two algorithms exhibit radically different behavior! 
                                    <Image src=".//img/pf/pf12.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Recursive Maze</h3>
                                    <p>Recursive Division Maze Generator is the fastest algorithm without directional biases. This algorithm is particularly fascinating because of its fractal nature: you could theoretically continue the process indefinitely at finer and finer levels of detail.</p>                                    
                                    This algorithm is somewhat similar to recursive backtracking, since they are both stack based, except this focuses on walls instead of passages. As a Wall Builders generator, the process begins with ample space and adds walls until the maze results.
                                    <Image src=".//img/pf/pf14.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Binary Tree Maze</h3>
                                    Binary Tree Maze Generator is one of the very rare algorithms with the ability to generate a perfect maze without keeping any state at all: it is an exact memory-less Maze generation algorithm with no limit to the size of Maze you can create. It can build the entire maze by looking at each cell independently. This is the most straightforward and fastest algorithm possible. Mazes generated are real Binary Tree Data Structure while having a very biased texture.                                    
                                    <Image src=".//img/pf/pf17.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Wilson’s Maze</h3>
                                    <p>Wilson’s algorithm uses loop-erased random walks to generate a uniform spanning tree — an unbiased sample of all possible spanning trees. Most other maze generation algorithms, such as Prim’s, random traversal and randomized depth-first traversal, do not have this beautiful property.</p>                                    
                                    <p>The algorithm initializes the maze with an arbitrary starting cell. Then, a new cell is added to the maze, initiating a random walk (shown in vivid colors). The random walk continues until it reconnects with the existing maze. However, if the random walk intersects itself, the resulting loop is erased before the random walk continues.</p>
                                    Initially, the algorithm can be frustratingly slow to watch, as the early random walks are unlikely to reconnect with the small existing maze. However, as the maze grows, the random walks become more likely to collide with the maze and the algorithm accelerates dramatically.
                                    <Image src=".//img/pf/pf19.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Search Algorithms</h3>
                                    Once a maze is created and start as finish nodes are positioned it is time to execute one of the five path search algorithms including Dijkstra’s, A-Star, Greedy, Breadth-First and Depth-First Search.                                     
                                    
                                    <h3>Dijkstra’s Search</h3>
                                    Dijkstra's Search is the father of pathfinding algorithms. The algorithm creates a tree of shortest paths from the starting vertex, the source, to all other points in the graph. It is a weighted algorithm and guarantees the shortest path.                                    
                                    <Image src=".//img/pf/pf5.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />
                                    <Image src=".//img/pf/pf6.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>A-Star Search</h3>
                                    A* Search is arguably the best pathfinding algorithm. It is weighted and uses heuristics to guarantee the shortest path much faster than Dijkstra's Algorithm. One major practical drawback is its space complexity, as it stores all generated nodes in memory.                                    
                                    <Image src=".//img/pf/pf10.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />
                                    <Image src=".//img/pf/pf11.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Greedy Search</h3>
                                    Greedy Best-first Search is a faster, more heuristic-heavy version of A*. It is weighted and does not guarantee the shortest path.                                    
                                    <Image src=".//img/pf/pf13.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Breadth-First Search</h3>
                                    Breath-First Search starts at the tree root, and explores all of the neighbor nodes at the present depth prior to moving on to the nodes at the next depth level. It is unweighted algorithm that guarantees the shortest path.                                    
                                    <Image src=".//img/pf/pf15.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />
                                    <Image src=".//img/pf/pf16.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Depth-First Search</h3>
                                    Depth-First Search starts at the root node and explores as far as possible along each branch before backtracking. It is unweighted algorithm that does not guarantee the shortest path.                                    
                                    <Image src=".//img/pf/pf18.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />
                                    <Image src=".//img/pf/pf24.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    <h3>Responsive UI Design</h3>
                                    The application is responsive in all screen sizes, including desktops, tablets and smartphones.   
                                    <Image src=".//img/pf/pf26.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />                                 
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenPF(!openPF); scrollTo('pf') }}
                                        aria-controls="pf-collapse-text"
                                        aria-expanded={openPF}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="vs">
            <FadeInSection  >
                <Row name="visualsorter" className="mt-5 pt-3 m-3 justify-content-center">
                    <Media >
                        <Image src=".//img/vs/vs7.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Visual Sorter</h2>
                        <h4>Vizualize seven sorting algorithms icluding </h4>
                        <h4>Selection, Bubble, Insertion, Shell, Quick, Merge and Heap Sort.</h4>
                        <h5>React · JavaScript · Node.js · CSS · HTML · Material Design · UI · UX</h5>
                        <Button
                            href='https://mindaugas.xyz/visualsorter'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenVS(!openVS)}
                            aria-controls="vs-collapse-text"
                            aria-expanded={openVS}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openVS}>
                            <div id="vs-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518327904' padding='62.58' />
                                    <h3>Sorting Visualizer</h3>
                                    Visual Sorter is an application for visualizing sorting algorithms.

                                    Built with React and Javascript, application rerenders view updates without the need to reload the page after the change of each state.
                                    An await / async functionality is used to slow down the computation and to visualize sorting in slow motion, step-by-step.

                                    <Image src=".//img/vs/vs3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    The programm is not only dynamic but also interactive. 
                                    User can rerandomize the array of bars, change the size of an array, resize the gap in between array elements and adjust the speed of the computation.

                                    <Image src=".//img/vs/vs8.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    Once a sorting algorithm is selected and starts running, buttons that shouldn't be triggered are automatically disabled.
                                    During the visualisation user can still play with the size of the gap in between bars or just get rid of it.
                                    Playfull colors inndicate a progress and a sequence of events, that are triggered one after the other to sort the given array.

                                    <Image src=".//img/vs/vs2.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-5" />

                                    The application is totally responsive in all sizes, including desktops, tablets and smartphones.                             The rest of the video will go through all the sorting algorithms one-by-one, but I suggest you just to try the app yourself.
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenVS(!openVS); ; scrollTo('vs') }}
                                        aria-controls="vs-collapse-text"
                                        aria-expanded={openVS}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="va">
            <FadeInSection >
                <Row name="vintageauctions" className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/va1.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Vintage Auctions</h2>
                        <h4>Find and buy trendy classic furniture.</h4>
                        <h4>Bid in auction listings or sell your own.</h4>
                        <h5>Django · Python · SQL · CSS · HTML · Bootstrap · UI · UX</h5>
                        <Button
                            href='http://vintageauctions.pythonanywhere.com/'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenVA(!openVA)}
                            aria-controls="va-collapse-text"
                            aria-expanded={openVA}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openVA}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518079405' padding='62.58' />
                                    <h3>Active listings</h3>
                                    Vintage Auctions is an e-commerce auction site that allows users to bid and post auction listings for trendy classic furniture.

                                    The front-end of the site is built with a Bootstrap CSS framework directed at responsive, mobile-first web development.

                                    The back-end is designed using Django, a high-level Python Web framework that encourages rapid development and clean, pragmatic design by following the model-template-views architectural pattern.

                                    <h3>Models</h3>

                                    The application has multiple Django models such as User, Listing, Bid, Comment, Watchlist and their corresponding Django forms.

                                    <h3>Create New listing</h3>
                                    All the data provided by the user to create a new listing is collected by Django Model Forms and stored at SQLite database.
                                    
                                    <Image src=".//img/va4.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />
                                    
                                    <h3>Listing's Page</h3>
                                    Listing's page allows users to place bids, comment, and add item to a watchlist.

                                    Clicking on a listing takes users to its page, where users are able to view all details about the listing, including the current price.

                                    If the user is signed in, the user is able to add the item to their “Watchlist.” If the item is already on the watchlist, the user is able to remove it.

                                    If the user is signed in, the user is able to bid on the item. The bid must be at least as large as the starting bid, and must be greater than any other bids that have been placed (if any). If the bid doesn’t meet those criteria, the user is presented with an error.

                                    If the user is signed in and is the one who created the listing, the user has the ability to “close” the auction from this page, which makes the highest bidder the winner of the auction and makes the listing no longer active.

                                    If a user is signed in on a closed listing page, and the user has won that auction, the page says so.

                                    Users who are signed are able to add comments to the listing page. The listing page displays all comments that have been made on the listing.

                                    <h3>Watchlist</h3>
                                    Users who are signed in are able to visit a Watchlist page, which displays all of the listings that a user has added to their watchlist. Clicking on any of those listings takes the user to that listing’s page.

                                    <Image src=".//img/va3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>Categories</h3>
                                    Clicking on the name of any category takes the user to a page that displays all of the active listings in that category.

                                    <h3>Mobile first responsive design</h3>
                                    The application has a responsive design in all screen sizes, including desktops, tablets and smartphones.

                                    <h3>Django Admin Interface</h3>
                                    Via the Django admin interface, a site administrator is able to view, add, edit, and delete any listings, comments, and bids made on the site.
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenVA(!openVA); scrollTo('va') }}
                                        aria-controls="va-collapse-text"
                                        aria-expanded={openVA}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="yr">
            <FadeInSection >
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/yr/yr.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>YouTube Rating</h2>
                        <h4>Chrome extension that shows YouTube video rating.</h4>
                        <h4>Replaces 'Share' button text with a rating like IMDB.</h4>
                        <h5>JavaScript · CSS · HTML</h5>
                        <Button
                            href='https://github.com/mindgis/youtubeRating'
                            target="_blank"
                            className="button"
                            variant="link">
                            GitHub ↗
                        </Button>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="bc">
            <FadeInSection >
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/bc/bc1.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>₿id€oin</h2>
                        <h4>Try investing in crypto market risk-free.</h4>
                        <h4>Compete with your friends or other players.</h4>
                        <h5>Flask · Python · SQL · CSS · HTML · Bootstrap</h5>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenBC(!openBC)}
                            aria-controls="bc-collapse-text"
                            aria-expanded={openBC}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openBC}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518928269' padding='57.42' />

                                    <h3>₿id€oin</h3>
                                    <p>Built with Python, Flask and SQLite BidCoin is a cryptocurrency simulator game for friends who love to brag how cleverly they would invest into digital coins if they would have a million bucks.</p>
                                    <p>This is the place to prove it and compete with your fellow friends without putting a second mortgage over your house or breaking the bank.</p>
                                    On the other hand one can actually give it a try before investing real money into the cryptocurrency market.

                                    <h3>Competition</h3>
                                    Main page hosts Competition among all the users where visitors can see who is leading the race in profiting from the bullish crypto market.

                                    <h3>Register</h3>
                                    Users are able to register for an account via a form by providing username and password. If username is taken or passwords do not match notification is rendered. Input is submitted via POST to a database where password is stored as a hash.
                                    
                                    <h3>Quote</h3>
                                    Quote section allows users to lookup a cryptocurrency’s current price. Once a coin symbol is submitted quotes are downloaded using API in JSON format and the current price is rendered.

                                    <h3>Buy / Sell</h3>
                                    Users can buy or sell shares by providing the cryptocurrency symbol and the number of shares. Transaction execution updates database accordingly.

                                    <Image src=".//img/bc/bc3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>Portfolio</h3>
                                    Portfolio section displays an HTML table summarising, which crypto coins the user owns, the current price of each coin, and a total value of each holding. Also displays a current cash balance along with a grand total. Information is retrieved by querying the database.

                                    <Image src=".//img/bc/bc5.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>History</h3>
                                    History section tracks all the user’s transactions ever, listing row by row each and every buy and every sell. Including coin symbol, price, number of coins sold or bought, and the date and time at which the transaction occurred.

                                    <Image src=".//img/bc/bc4.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => { setOpenBC(!openBC); scrollTo('bc') }}
                                        aria-controls="bc-collapse-text"
                                        aria-expanded={openBC}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="sn">
            <FadeInSection >
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/sn/sn2.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Social Network</h2>
                        <h4>Connect with people. Follow influencers.</h4>
                        <h4>Build your audience. Share the news and your views.</h4>
                        <h5>Javascript · CSS · HTML · Bootstrap</h5>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenSN(!openSN)}
                            aria-controls="sn-collapse-text"
                            aria-expanded={openSN}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openSN}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518482642' padding='90' />

                                    <h3>Social network</h3>
                                    Built with Python, JavaScript, HTML, and CSS, the implementation of a social network allows users to make or like posts and follow other users.

                                    <h3>New Post</h3>
                                    Users who are signed in are able to write a new text-based post by filling in text into a text area and then clicking a button to submit the post.
                                    
                                    <h3>All Posts</h3>

                                    The “All Posts” link in the navigation bar takes the user to a page where they can see all posts from all users, with the most recent posts first.

                                    Each post includes the username of the poster, the content itself, the date and time at which it was made, and the number of “likes” it has.
                                    
                                    <h3>Profile Page</h3>
                                    Clicking on a username loads that user’s profile page that displays the number of followers the user has, as well as the number of people that the user follows and also displays all of the posts for that user, in reverse chronological order.

                                    For any other user who is signed in, this page displays a “Follow” or “Unfollow” button that lets the current user toggle whether or not they are following this user’s posts.                                     
                                    
                                    <h3>Following</h3>
                                    The “Following” link in the navigation bar takes the user to a page where they see all posts made by users that the current user follows.

                                    This page behaves just as the “All Posts” page does, just with a more limited set of posts and is available only to users who are signed in.

                                    <Image src=".//img/sn/sn3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>Pagination</h3>
                                    On any page that displays posts, only 10 posts are being displayed. Django’s Paginator class is used for implementing pagination on the back-end. Bootstrap’s Pagination features are used for displaying pages on the front-end.

                                    <h3>Edit Post</h3>
                                    When a user clicks “Edit” for one of their own posts, the content of their post is being replaced with a textarea where the user can edit the content of their post.

                                    The user is then able to “Save” the edited post. Using JavaScript, this is achived without requiring a reload of the entire page.

                                    <h3>“Like” and “Unlike”</h3>
                                    Users are able to click a button on any post to toggle whether or not they “like” it.

                                    Using JavaScript, the server asynchronously updates the like count via a call to fetch and then updates the post’s like count displayed on the page, without requiring a reload of the entire page.                                

                                    <h3>Mobile first responsive design</h3>
                                    The application has a responsive design in all screen sizes, including desktops, tablets and smartphones.
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenSN(!openSN); ; scrollTo('sn') }}
                                        aria-controls="sn-collapse-text"
                                        aria-expanded={openSN}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>
            
            <div id="ec">
            <FadeInSection >
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/ec/ec1.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Email Client</h2>
                        <h4>Keep your mailbox light and simple.</h4>
                        <h4>Yet have all the functionality that you  need.</h4>
                        <h5>Javascript · CSS · HTML · Bootstrap</h5>
                        <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenEC(!openEC)}
                            aria-controls="sn-collapse-text"
                            aria-expanded={openEC}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openEC}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518495808' padding='90' />

                                    <h3>EmailClient</h3>
                                    EmailClient is a front-end for an email client that makes API calls to send and receive emails.

                                    The app is built mainly with Javascript on top of Python's framework Django.

                                    <h3>Send Mail</h3>
                                    When a user submits the email composition form, a POST request passes values of recipients, subject and body to /emails route. Once email has been sent, user's SENT mailbox is loaded.

                                    <h3>Mailbox</h3>
                                    When a user visits their INBOX, SENT mailbox or ARCHIVE, the appropriate mailbox is loaded from JSON response.
                                    When a mailbox is visited, the application first queries the API for the latest emails in that mailbox.
                                    Each email is then rendered in its own 'div' box that displays who the email is from, what the subject line is, and the timestamp of the email.
                                    If the email is unread, it appears with a white background. Otherwise with a gray background and muted text.
                                    
                                    <Image src=".//img/ec/ec3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>View Email</h3>
                                    When a user clicks on an email, the user is taken to a view where they see the content of that email.
                                    It shows the email’s sender, recipients, subject, timestamp, and body.
                                    Once the email has been clicked on, the email is marked as read.
                                    Additional 'div' element is added for displaying the email. Code hides and shows the right views when navigation options are clicked.

                                    <h3>Archive and Unarchive</h3>
                                    When viewing an email, the user is presented with a button that lets them archive or unarchive the email.
                                    Once an email has been archived or unarchived, the user’s inbox is loaded.

                                    <h3>Reply</h3>
                                    When viewing an email, the user is presented with a “Reply” button that lets them reply to the email.
                                    When the user clicks the “Reply” button, they are taken to the email composition form.
                                    The composition form is pre-filled with the recipient field set to whoever sent the original email, the subject line and the body of the email with details of the original email.
                                    </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenEC(!openEC); scrollTo('ec') }}
                                        aria-controls="ec-collapse-text"
                                        aria-expanded={openEC}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="iv">
            <FadeInSection id="iv">
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/imaginevisuals.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Portfolio Website</h2>
                        <h4>Website for a visualisation studio.</h4>
                        <h4>Visualise architecture.</h4>
                        <h5>React · Gatsby · Javascript · HTML · CSS · UI · UX</h5>
                        <Button
                            href='https://imaginevisuals.com'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        {/* <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenSN(!openSN)}
                            aria-controls="sn-collapse-text"
                            aria-expanded={openSN}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openSN}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518482642' padding='90' />

                                    <h3>Social network</h3>
                                    Built with Python, JavaScript, HTML, and CSS, the implementation of a social network allows users to make or like posts and follow other users.

                                    <h3>New Post</h3>
                                    Users who are signed in are able to write a new text-based post by filling in text into a text area and then clicking a button to submit the post.
                                    
                                    <h3>All Posts</h3>

                                    The “All Posts” link in the navigation bar takes the user to a page where they can see all posts from all users, with the most recent posts first.

                                    Each post includes the username of the poster, the content itself, the date and time at which it was made, and the number of “likes” it has.
                                    
                                    <h3>Profile Page</h3>
                                    Clicking on a username loads that user’s profile page that displays the number of followers the user has, as well as the number of people that the user follows and also displays all of the posts for that user, in reverse chronological order.

                                    For any other user who is signed in, this page displays a “Follow” or “Unfollow” button that lets the current user toggle whether or not they are following this user’s posts.                                     
                                    
                                    <h3>Following</h3>
                                    The “Following” link in the navigation bar takes the user to a page where they see all posts made by users that the current user follows.

                                    This page behaves just as the “All Posts” page does, just with a more limited set of posts and is available only to users who are signed in.

                                    <Image src=".//img/sn/sn3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>Pagination</h3>
                                    On any page that displays posts, only 10 posts are being displayed. Django’s Paginator class is used for implementing pagination on the back-end. Bootstrap’s Pagination features are used for displaying pages on the front-end.

                                    <h3>Edit Post</h3>
                                    When a user clicks “Edit” for one of their own posts, the content of their post is being replaced with a textarea where the user can edit the content of their post.

                                    The user is then able to “Save” the edited post. Using JavaScript, this is achived without requiring a reload of the entire page.

                                    <h3>“Like” and “Unlike”</h3>
                                    Users are able to click a button on any post to toggle whether or not they “like” it.

                                    Using JavaScript, the server asynchronously updates the like count via a call to fetch and then updates the post’s like count displayed on the page, without requiring a reload of the entire page.                                

                                    <h3>Mobile first responsive design</h3>
                                    The application has a responsive design in all screen sizes, including desktops, tablets and smartphones.
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenSN(!openSN); ; scrollTo('sn') }}
                                        aria-controls="sn-collapse-text"
                                        aria-expanded={openSN}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse> */}
                    </div>
                </Row>
            </FadeInSection>
            </div>

            <div id="sw">
            <FadeInSection id="sw">
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/minde-cover.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Startup Website</h2>
                        <h4>Website for a branding agency.</h4>
                        <h4>Create a brand for architecture.</h4>
                        <h5>UI · UX · Webflow · HTML · CSS</h5>
                        <Button
                            href='https://minde.ch'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        {/* <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenSN(!openSN)}
                            aria-controls="sn-collapse-text"
                            aria-expanded={openSN}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openSN}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518482642' padding='90' />

                                    <h3>Social network</h3>
                                    Built with Python, JavaScript, HTML, and CSS, the implementation of a social network allows users to make or like posts and follow other users.

                                    <h3>New Post</h3>
                                    Users who are signed in are able to write a new text-based post by filling in text into a text area and then clicking a button to submit the post.
                                    
                                    <h3>All Posts</h3>

                                    The “All Posts” link in the navigation bar takes the user to a page where they can see all posts from all users, with the most recent posts first.

                                    Each post includes the username of the poster, the content itself, the date and time at which it was made, and the number of “likes” it has.
                                    
                                    <h3>Profile Page</h3>
                                    Clicking on a username loads that user’s profile page that displays the number of followers the user has, as well as the number of people that the user follows and also displays all of the posts for that user, in reverse chronological order.

                                    For any other user who is signed in, this page displays a “Follow” or “Unfollow” button that lets the current user toggle whether or not they are following this user’s posts.                                     
                                    
                                    <h3>Following</h3>
                                    The “Following” link in the navigation bar takes the user to a page where they see all posts made by users that the current user follows.

                                    This page behaves just as the “All Posts” page does, just with a more limited set of posts and is available only to users who are signed in.

                                    <Image src=".//img/sn/sn3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>Pagination</h3>
                                    On any page that displays posts, only 10 posts are being displayed. Django’s Paginator class is used for implementing pagination on the back-end. Bootstrap’s Pagination features are used for displaying pages on the front-end.

                                    <h3>Edit Post</h3>
                                    When a user clicks “Edit” for one of their own posts, the content of their post is being replaced with a textarea where the user can edit the content of their post.

                                    The user is then able to “Save” the edited post. Using JavaScript, this is achived without requiring a reload of the entire page.

                                    <h3>“Like” and “Unlike”</h3>
                                    Users are able to click a button on any post to toggle whether or not they “like” it.

                                    Using JavaScript, the server asynchronously updates the like count via a call to fetch and then updates the post’s like count displayed on the page, without requiring a reload of the entire page.                                

                                    <h3>Mobile first responsive design</h3>
                                    The application has a responsive design in all screen sizes, including desktops, tablets and smartphones.
                                </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenSN(!openSN); ; scrollTo('sn') }}
                                        aria-controls="sn-collapse-text"
                                        aria-expanded={openSN}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse> */}
                    </div>
                </Row>
            </FadeInSection>
            </div>
            
            <div id="pw">
            <FadeInSection >
                <Row className="mt-5 pt-3 m-3 justify-content-center">
                    <Media>
                        <Image src=".//img/jona-cover.jpg" fluid className="shadow-xl rounded-xl" />
                    </Media>
                    <div className="title mt-5 mb-3">
                        <h2>Project Website</h2>
                        <h4>Website for a real estate development.</h4>
                        <h4>Fall in love with your new dream home.</h4>
                        <h5>UI · UX · Webflow · HTML · CSS</h5>
                        <Button
                            href='https://minde.ch/jona'
                            target="_blank"
                            className="button"
                            variant="link">
                            Website ↗
                        </Button>
                        {/* <Button
                            className="button"
                            variant="link"
                            onClick={() => setOpenEC(!openEC)}
                            aria-controls="sn-collapse-text"
                            aria-expanded={openEC}
                        >
                            More ↓
                        </Button>
                        <Collapse in={openEC}>
                            <div id="va-collapse-text" >
                                <div className="more">
                                    <Vimeo vimeoId='518495808' padding='90' />

                                    <h3>EmailClient</h3>
                                    EmailClient is a front-end for an email client that makes API calls to send and receive emails.

                                    The app is built mainly with Javascript on top of Python's framework Django.

                                    <h3>Send Mail</h3>
                                    When a user submits the email composition form, a POST request passes values of recipients, subject and body to /emails route. Once email has been sent, user's SENT mailbox is loaded.

                                    <h3>Mailbox</h3>
                                    When a user visits their INBOX, SENT mailbox or ARCHIVE, the appropriate mailbox is loaded from JSON response.
                                    When a mailbox is visited, the application first queries the API for the latest emails in that mailbox.
                                    Each email is then rendered in its own 'div' box that displays who the email is from, what the subject line is, and the timestamp of the email.
                                    If the email is unread, it appears with a white background. Otherwise with a gray background and muted text.
                                    
                                    <Image src=".//img/ec/ec3.jpg" fluid className="shadow-xl rounded-xl mt-5 mb-3" />

                                    <h3>View Email</h3>
                                    When a user clicks on an email, the user is taken to a view where they see the content of that email.
                                    It shows the email’s sender, recipients, subject, timestamp, and body.
                                    Once the email has been clicked on, the email is marked as read.
                                    Additional 'div' element is added for displaying the email. Code hides and shows the right views when navigation options are clicked.

                                    <h3>Archive and Unarchive</h3>
                                    When viewing an email, the user is presented with a button that lets them archive or unarchive the email.
                                    Once an email has been archived or unarchived, the user’s inbox is loaded.

                                    <h3>Reply</h3>
                                    When viewing an email, the user is presented with a “Reply” button that lets them reply to the email.
                                    When the user clicks the “Reply” button, they are taken to the email composition form.
                                    The composition form is pre-filled with the recipient field set to whoever sent the original email, the subject line and the body of the email with details of the original email.
                                    </div>
                                <div className="float-center mt-3"> 
                                    <Button
                                        className="button"
                                        variant="link"
                                        onClick={() => {setOpenEC(!openEC); scrollTo('ec') }}
                                        aria-controls="ec-collapse-text"
                                        aria-expanded={openEC}
                                    >
                                        Less ↑
                                    </Button>
                                </div>
                            </div>
                        </Collapse> */}
                    </div>
                </Row>
            </FadeInSection>
            </div>

        </Container>
    )
}

export default Folio
