import Folio from './components/Folio'
import './App.css';
// import Loader from './components/Loader.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import React, { Suspense } from 'react';
    
// const Folio = React.lazy(() => import('./components/Folio'));

function App() {
  return (
    <Router>
      <div className="App">
            {/* <Loader /> */}
            {/* <Folio /> */}
        <Switch>
          {/* <Route path="/" exact component={Folio} /> */}
          <Route path="*" component={Folio} />
        </Switch>
      </div>


    </Router>

  );
}

export default App;
